import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Button, DatePicker, Row, Select } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
const { Option } = Select;
import { SERVER_URL } from '../../config';

const EopStatistic = () => {
  const currentuser = useContext(UserContext);
  const [data, fetchData] = useAxios('', [], currentuser.data.token, 'get');
  const [eopClients, fetchEopClients] = useAxios('', [], currentuser.data.token, 'get');
  const [eopUniqueClients, fetchEopUniqueClients] = useAxios('', [], currentuser.data.token, 'get');
  const [filter, setFilter] = useState({});
  const [selectedYear, setSelectedYear] = useState({});
  const [filterClients, setFilterClients] = useState({});
  const [form] = Form.useForm();
  const [formClients] = Form.useForm();
  const [users, fetchUsers] = useAxios('', {}, currentuser.data.token, 'get');
  useEffect(() => {
    fetchUsers(
      `${SERVER_URL}/users?filter=` + encodeURIComponent(JSON.stringify({ role: { $nin: ['superAdmin'] } })),
      [],
    );
  }, []);

  useEffect(() => {
    fetchData(`${SERVER_URL}/eop?filter=` + encodeURIComponent(JSON.stringify(filter)), []);
  }, [data, filter]);

  useEffect(() => {
    fetchEopClients(`${SERVER_URL}/eop-clients?filter=` + encodeURIComponent(JSON.stringify(filterClients)), []);
  }, [eopClients, filterClients]);

  useEffect(() => {
    fetchEopUniqueClients(
      `${SERVER_URL}/eop-unique-clients?filter=` + encodeURIComponent(JSON.stringify(selectedYear)),
      [],
    );
  }, [eopUniqueClients, selectedYear]);

  const onFinish = (values) => {
    const filter = {};

    if (values.category) filter['realEstate.category'] = values.category;
    if (values.type) filter['realEstate.type'] = values.type;
    if (values.user) filter.createdByUser = values.user;
    if (values.year) {
      const yearValue = values.year;
      const dateString = yearValue.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const yearRegex = /(\d{4})-/; // Matches the first four digits followed by a hyphen
      const year = yearRegex.exec(dateString)[1];
      filter.entryDate = {
        $regex: year,
      };
    }
    setFilter(filter);
  };

  const onFinishEopClients = (values) => {
    console.log(values);
    const filter = {};
    if (values.type) filter['realEstate.type'] = values.type;
    if (values.year) {
      const yearValue = values.year;
      const dateString = yearValue.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const yearRegex = /(\d{4})-/; // Matches the first four digits followed by a hyphen
      const year = yearRegex.exec(dateString)[1];
      filter['eop.entryDate'] = {
        $regex: year,
      };
      setSelectedYear({
        'eop.entryDate': {
          $regex: year,
        },
      });
    }
    if (values.clientCategory) {
      const clientTypes = Array.isArray(values.clientCategory) ? values.clientCategory : [values.clientCategory];
      filter['clientType'] = {
        $in: clientTypes,
      };
    }
    if (values.typeClient) {
      filter['eop.typeClient'] = values.typeClient;
    }
    if (values.businessShip) {
      filter['eop.businessShip'] = values.businessShip;
    }
    if (values.domicile) {
      filter['eop.domicile'] = values.domicile;
    }
    if (values.residency) {
      filter['eop.residency'] = values.residency;
    }
    if (values.riskType) {
      filter['eop.riskType'] = values.riskType;
    }
    setFilterClients(filter);
  };

  return (
    <div className='table'>
      <div style={{ borderBottom: '1px solid #e8e8e8', paddingBottom: '20px' }}>
        <div className='statistic-block'>
          <h3>Filter preseka prodaje/izdavanja:</h3>

          <Form className='form-horizontal' onFinish={(values) => onFinish(values)} layout='horizontal' form={form}>
            <Row type='flex' gutter={0}>
              <Form.Item label='Godina' name='year'>
                <DatePicker picker='year' />
              </Form.Item>
              {!users.isLoading && users.data && users.data.items && users.data.items.length > 0 && (
                <Form.Item label='Zaposleni' name='user'>
                  <Select virtual={false} allowClear='true' placeholder='Izaberite zaposlenog'>
                    {users.data.items.map((user, key) => (
                      <Option key={key} value={user._id}>{`${user.firstName} ${user.lastName}`}</Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item label='Vrsta' name='type'>
                <Select virtual={false} allowClear='true' placeholder='Izaberi vrstu' style={{ width: '150px' }}>
                  {[
                    'kuća',
                    'stan',
                    // 'garsonjera',
                    'poslovni prostor',
                    'vikendica',
                    'plac',
                    'zemlja',
                    'garaža',
                    'salaš',
                    'voćnjak/vinograd',
                  ].map((option, key) => (
                    <Option key={key} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Kategorija' name='category'>
                <Select virtual={false} allowClear='true' placeholder='Izaberi kategoriju' style={{ width: '150px' }}>
                  {['prodaja', 'prodaja/zamena', 'izdavanje'].map((option, key) => (
                    <Option key={key} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Button type='primary' htmlType='submit'>
                Izbroj
              </Button>
            </Row>
          </Form>
        </div>
        <h4 style={{ paddingLeft: '30px', margin: '0px', fontWeight: '600' }}>
          Presek prodaje/izdavanja: {data && data.data && data.data.items ? data.data.count : 0}
        </h4>
      </div>
      <div style={{ borderBottom: '1px solid #e8e8e8', paddingBottom: '20px' }}>
        <div className='statistic-block'>
          <h3>Filter preseka lica iz eop-a:</h3>

          <Form
            className='form-horizontal'
            onFinish={(values) => onFinishEopClients(values)}
            layout='horizontal'
            form={formClients}
          >
            <Row type='flex' gutter={0}>
              <Form.Item label='Godina' name='year'>
                <DatePicker picker='year' />
              </Form.Item>
              <Form.Item label='Vrsta objekta' name='type'>
                <Select
                  virtual={false}
                  allowClear='true'
                  placeholder='Izaberi vrstu objekta'
                  style={{ width: '200px' }}
                >
                  {[
                    'kuća',
                    'stan',
                    // 'garsonjera',
                    'poslovni prostor',
                    'vikendica',
                    'plac',
                    'zemlja',
                    'garaža',
                    'salaš',
                    'voćnjak/vinograd',
                  ].map((option, key) => (
                    <Option key={key} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Kategorija lica' name='clientCategory'>
                <Select
                  virtual={false}
                  allowClear='true'
                  placeholder='Izaberi kategoriju lica'
                  style={{ width: '200px' }}
                >
                  {['prodavac', 'punomoćnik', 'suvlasnik', 'zakupodavac'].map((option, key) => (
                    <Option key={key} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label='Vrsta lica' name='typeClient'>
                <Select virtual={false} allowClear='true' placeholder='Izaberi vrstu lica' style={{ width: '200px' }}>
                  {[
                    'Fizičko lice',
                    'Preduzetnik',
                    'D.O.O',
                    'Komanditno društvo',
                    'Ortačko društvo',
                    'Akcionarsko društvo',
                    'Poslovno udruženje',
                    'Zadruga',
                    'Poljoprivredno gazdinstvo',
                    'Lice gr. prava',
                    'Udruženje',
                    'Zadužbina',
                    'Fondacija',
                    'Udruženje-nevladina organizacija',
                    'Sportsko udruženje',
                    'Verska zajednica i crkva',
                    'Politička stranka',
                    'Sindikat',
                    'Jedinica lokalne samouprave',
                    'Ustanova',
                    'Javno preduzeće',
                    'Strano predstavništvo',
                    'Ogranak stranog privrednog društva',
                    'Trast',
                    'Ostalo',
                  ].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Row type='flex' gutter={0}>
                <Form.Item label='Rezidentnost' name='residency'>
                  <Select
                    virtual={false}
                    allowClear='true'
                    style={{ width: '200px' }}
                    placeholder='Izaberi rezidentnost'
                  >
                    {[
                      'REZ - domaći',
                      'REZ - granična područja',
                      'NER - stranac iz okruženja',
                      'NER - stranac bela lista',
                      'NER - stranac srednji georizik',
                      'NER - stranac crna lista',
                    ].map((type, key) => (
                      <Option key={key} value={type}>{`${type}`}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label='Država prebivališta/sedišta' name='domicile'>
                  <Select
                    virtual={false}
                    allowClear='true'
                    style={{ width: '200px' }}
                    placeholder='Izaberi državu prebivališta'
                  >
                    {[
                      'Srbija',
                      'Crna Gora',
                      'BiH',
                      'Hrvatska',
                      'Slovenija',
                      'Severna Makedonija',
                      'Grčka',
                      'Švajcarska',
                      'Francuska',
                      'Španija',
                      'Velika Britanija',
                      'Italija',
                      'Turska',
                      'Mađarska',
                      'Rusija',
                      'Holandija',
                      'Bugarska',
                      'USA',
                      'Kanada',
                      'Nemačka',
                      'Malta',
                      'Austrija',
                      'Australija',
                      'Izrael',
                      'Liban',
                      'Sirija',
                      'Kina',
                      'Ukrajina',
                      'Iran',
                      'Švedska',
                      'Kipar',
                      'Egipat',
                      'Jordan',
                      'Rumunija',
                      'Irak',
                      'Irska',
                      'Novi Zeland',
                      'Slovačka',
                      'Singapur',
                      'Japan',
                      'Kuba',
                      'Belgija',
                      'Južnoafrička Republika',
                      'Finska',
                      'Norveška',
                      'Luksemburg',
                      'Češka',
                      'Južna Koreja',
                      'Severna Koreja',
                      'Belorusija',
                      'Kongo',
                      'Polska',
                      'Venecuela',
                      'UAE',
                      'Pakistan',
                      'Filipini',
                      'Azerbejdžan',
                      'Litvanija',
                    ].map((type, key) => (
                      <Option key={key} value={type}>{`${type}`}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>

              <Form.Item label='Kategorija rizika' name='riskType'>
                <Select
                  virtual={false}
                  allowClear='true'
                  style={{ width: '200px' }}
                  placeholder='Izaberi kategoriju rizika'
                >
                  {['Nisko rizičan', 'Srednje rizičan', 'Visoko rizičan'].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label='Uspostavljen poslovni odnos' name='businessShip'>
                <Select
                  virtual={false}
                  allowClear='true'
                  style={{ width: '200px' }}
                  placeholder='Izaberi uspostavljen poslovni odnos'
                >
                  {[
                    'LIČNO',
                    'VIDEO Identifikacijom',
                    'Preko PUNOMOĆNIKA',
                    'Preko ADVOKATA',
                    'Preko drugog POSREDNIKA',
                    'drugo',
                  ].map((type, key) => (
                    <Option key={key} value={type}>{`${type}`}</Option>
                  ))}
                </Select>
              </Form.Item>

              <Button type='primary' htmlType='submit'>
                Izbroj
              </Button>
            </Row>
          </Form>
        </div>
        <h4 style={{ paddingLeft: '30px', margin: '0px', fontWeight: '600' }}>
          Presek lica iz eop-a: {eopClients && eopClients.data && eopClients.data.items ? eopClients.data.count : 0}
        </h4>
        <h4 style={{ paddingLeft: '30px', margin: '0px', fontWeight: '600' }}>
          Ukupan broj lica sa kojima se radilo u izabranoj godini:{' '}
          {eopUniqueClients && eopUniqueClients.data && eopUniqueClients.data.items ? eopUniqueClients.data.count : 0}
        </h4>
      </div>
    </div>
  );
};

export default EopStatistic;
